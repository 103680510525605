<template>
    <base-dialog
        width="722"
        :title="dynamicTitles.card"
        :is-open.sync="isOpenLocal"
    >
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <base-input-label required hint="max. 5mb"
                            >Image</base-input-label
                        >
                        <div class="d-flex justify-space-between align-center">
                            <v-file-input
                                solo
                                rounded
                                :prepend-icon="null"
                                show-size
                                small-chips
                                color="secondary"
                                flat
                                hide-details
                                accept="image/*"
                                class="rounded-lg"
                                id="attachment"
                                clearable
                                v-model="form.image"
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip small label color="secondary">
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>

                            <div
                                class="mx-2 caption font-weight-bold secondary--text"
                            ></div>

                            <v-btn
                                class="caption font-weight-bold text-capitalize"
                                small
                                depressed
                                rounded
                                dark
                                color="secondary"
                                @click="openFileInput('attachment')"
                                >Select File</v-btn
                            >
                        </div>

                        <v-menu
                            max-width="20rem"
                            top
                            v-if="operation.editMode && form.existing_image"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pt-2">
                                    <span
                                        class="caption text-decoration-underline secondary--text c-pointer"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        View existing image
                                    </span>
                                </div>
                            </template>
                            <v-card width="20rem">
                                <v-img
                                    :src="form.existing_image"
                                    width="100%"
                                    height="auto"
                                ></v-img>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Event Name</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.event_name"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required
                            >Description</base-input-label
                        >
                        <v-textarea
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.description"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Event Type</base-input-label>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            append-icon="mdi-chevron-down"
                            hide-details
                            color="secondary"
                            :items="eventTypes"
                            item-value="value"
                            item-text="text"
                            v-model="form.is_public"
                        ></v-select>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Location</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.location"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="3">
                                <base-input-label>Date & Time</base-input-label>
                                <v-checkbox
                                    color="secondary"
                                    hide-details
                                    v-model="form.is_allday"
                                    @change="form.event_start = null; form.event_end = null;"
                                >
                                    <template v-slot:label>
                                        <div
                                            class="text-uppercase subtitle-2 font-weight-bold"
                                        >
                                            All Day
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="12" md="9">
                                <v-row v-if="form.is_allday">
                                    <v-col cols="12">
                                        <base-input-label required
                                            >Select Date</base-input-label
                                        >
                                        <base-date-picker
                                            solo
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :min="currentDate"
                                            v-model="form.event_start"
                                        ></base-date-picker>
                                    </v-col>
                                </v-row>

                                <v-row v-else>
                                    <v-col cols="12" md="6">
                                        <base-input-label required
                                            >Start Time</base-input-label
                                        >
                                        <base-datetime-picker
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :min="currentDate"
                                            v-model="form.event_start"
                                        ></base-datetime-picker>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <base-input-label required
                                            >End Time</base-input-label
                                        >
                                        <base-datetime-picker
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :disabled="!form.event_start"
                                            :min="form.event_start"
                                            v-model="form.event_end"
                                        ></base-datetime-picker>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import BaseDatePicker from "@/components/Base/BaseDatePicker";
import BaseDatetimePicker from "@/components/Base/BaseDateTimePicker";
import { CREATE_EVENT, UPDATE_EVENT } from "@/store/modules/event";
import dateUtility from "@/mixins/date";

const defaultForm = {
    image: null,
    event_name: null,
    description: null,
    is_public: 1,
    location: null,
    is_allday: false,
    event_start: null,
    event_end: null,
    existing_image: null,
};

export default {
    name: "engage-event-form-dialog",

    components: { BaseDatePicker, BaseDatetimePicker, BaseInputLabel, BaseDialog },

    props: {
        isOpen: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
            form: Object.assign({}, defaultForm),
            isActionStart: false,
            error: null,
            eventID: null,
        };
    },

    computed: {
        eventTypes() {
            return [
                {
                    value: 1,
                    text: "Public",
                },

                {
                    value: 0,
                    text: "Private",
                },
            ];
        },

        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
                thumbnail: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add Event",
                        thumbnail: "Thumbnail",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit Event",
                        thumbnail: "Change Thumbnail",
                    }
                );
            }

            return title;
        },

        currentDate() {
            return dateUtility.currentDate();
        },
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },

        isOpenLocal(value) {
            if (!value && this.operation.editMode) {
                this.resetForm();
            }
            this.$emit("update:isOpen", value);
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                this.eventID = value.id;
                this.form = Object.assign(
                    {},
                    {
                        image: null,
                        event_name: value.event_name || null,
                        description: value.description || null,
                        is_public: value.event_type === "Public" ? 1 : 0,
                        location: value.location || null,
                        is_allday: !!value.is_allday,
                        event_start:
                            dateUtility.toLocalDate(value.event_start) || null,
                        event_end: value.event_end
                            ? dateUtility.toLocalDate(value.event_end)
                            : null,
                        existing_image: value.image,
                    }
                );
            }
        },
    },

    methods: {
        openFileInput(elementId) {
            this.$nextTick(() => {
                document.getElementById(elementId).click();
            });
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        async onCreate() {
            const payload = {
                ...this.form,
                event_start: dateUtility.toUTC(this.form.event_start),
                event_end: !this.form.is_allday
                    ? dateUtility.toUTC(this.form.event_end)
                    : null,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                CREATE_EVENT,
                payload
            );
            if (success) {
                this.isOpenLocal = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Engage",
                    text: "Event was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            const payload = {
                ...this.form,
                eventID: this.eventID,
                event_start: dateUtility.toUTC(this.form.event_start),
                event_end: !this.form.is_allday
                    ? dateUtility.toUTC(this.form.event_end)
                    : null,
            }; 
            console.log("payload: ", payload)
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_EVENT,
                payload
            );
            if (success) {
                this.isOpenLocal = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Engage",
                    text: "Event was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },
    },
};
</script>
