<template>
    <div>
        <v-container
            id="users"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="4" cols="12" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Engage</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedEngagePage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="engageItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort by"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="width: 15rem"
                            clearable
                            @click:clear="table.options.sortBy = null"
                            :disabled="disabledFilter"
                            v-model="table.options.sortBy"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="width: 8rem; color: black !important"
                            clearable
                            @click:clear="table.options.perPage = 10"
                            :disabled="disabledFilter"
                            v-model="table.pagination.perPage"
                        ></v-select>
                        <!-- <div class="mx-1"></div> -->
                        <!-- <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="View"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="dataContainers"
                            style="width: 11.25rem; color: black !important"
                            v-model="mode"
                        ></v-select> -->
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <v-btn
                            class="border-radius-25 px-5 mx-1"
                            depressed
                            rounded
                            dark
                            color="#2C423D"
                            @click="openEventFormDialog('add')"
                            >Add Event</v-btn
                        >
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <!-- <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="width: 16rem"
                            color="secondary"
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Filter by location"
                            item-text="name"
                            item-value="name"
                            :items="[]"
                            clearable
                            @click:clear="table.options.membership = null"
                            :disabled="disabledFilter"
                        ></v-select>
                        <div class="mx-1"></div> -->
                        <v-text-field
                            clearable
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            :disabled="disabledFilter"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col
                        cols="12"
                        :style="{
                            display: mode === 'table' ? 'block' : 'none',
                        }"
                    >
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.image="{ item }">
                                <v-img
                                    class="border-radius-10 mx-auto"
                                    :lazy-src=" item.image"
                                    :src="item.image"
                                    height="80"
                                    max-height="80"
                                    max-width="130"
                                ></v-img>
                            </template>
                            <template v-slot:item.event_date="{ item }">
                                {{ formatDate(item) }}
                            </template>
                            <template v-slot:item.inviteLink="{ item }">
                                <span
                                    ><span class="text-decoration-underline">{{
                                        item.invite_link
                                    }}</span>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="
                                                    copyToClipboard(
                                                        item.invite_link
                                                    )
                                                "
                                                icon
                                                ><v-icon small color="#369776"
                                                    >mdi-content-copy</v-icon
                                                ></v-btn
                                            >
                                        </template>
                                        <span>Copy Invite Link</span>
                                    </v-tooltip></span
                                >
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#78756A20'"
                                        rounded
                                        @click="
                                            openEventInformationDialog(item)
                                        "
                                    >
                                        <v-icon
                                            :color="hover ? '#fff' : '#78756A'"
                                            >mdi-fullscreen</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        :style="{
                            display: mode === 'calendar' ? 'block' : 'none',
                        }"
                    >
                        <v-card
                            color="transparent"
                            height="64"
                            rounded
                            class="border-radius-10"
                            flat
                            :loading="calendar.loading"
                        >
                            <v-toolbar flat color="transparent">
                                <v-btn
                                    fab
                                    x-small
                                    style="background: rgba(54, 151, 118, 0.15)"
                                    @click="prev"
                                    elevation="0"
                                    class="mx-1"
                                >
                                    <v-icon color="success">
                                        mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    style="background: rgba(54, 151, 118, 0.15)"
                                    @click="next"
                                    elevation="0"
                                    class="mx-1"
                                >
                                    <v-icon color="success">
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-toolbar-title
                                    class="ml-2 font-weight-bold"
                                    v-if="$refs.calendar"
                                >
                                    {{ getYear }}
                                </v-toolbar-title>
                                <v-toolbar-title
                                    class="ml-2 success--text font-weight-bold"
                                    v-if="$refs.calendar"
                                >
                                    {{ getMonth }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </v-card>
                        <p class="text-center" v-show="this.calendar.loading">
                            Loading Events...
                        </p>
                        <v-sheet rounded v-show="!this.calendar.loading">
                            <v-calendar
                                style="border-radius: inherit"
                                ref="calendar"
                                color="success"
                                rounded
                                @click:event="onClickCalendarEvent"
                                @click:more="onClickCalendarMore"
                                @click:day="onClickCalendarMore"
                                @click:date="onClickCalendarMore"
                                :events="calendar.items"
                                v-model="focus"
                            >
                                <template v-slot:event="{ event }">
                                    <span class="font-weight-bold">
                                        {{ event.data.event_name }}
                                    </span>
                                    <span>{{ formatDate(event.data) }}</span>
                                </template>
                            </v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <event-list-dialog
            :is-open.sync="eventList.show"
            :action="eventList.action"
            :data="eventList.data"
            @close="eventList = { ...eventList, show: false, data: null }"
            @onClickCalendarEvent="onClickCalendarEvent"
        ></event-list-dialog>

        <engage-event-form-dialog
            :is-open.sync="eventForm.show"
            :action="eventForm.action"
            :data="eventForm.data"
            @onSuccess="onFinishUpdateDeleteEvent"
            @close="eventForm = { ...eventForm, show: false, data: null }"
        ></engage-event-form-dialog>

        <engage-event-information-dialog
            :is-open.sync="eventInformationDialog.show"
            :data="eventInformationDialog.data"
            @close="eventInformationDialog.show = false"
            @showUpdateForm="openEventFormDialog('edit')"
            @onDeleteSuccess="onFinishUpdateDeleteEvent"
            v-if="eventInformationDialog.show"
        ></engage-event-information-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.from"
            :end-date.sync="table.options.to"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import Pagination from "../../../Core/Pagination.vue";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import EventListDialog from "./components/EventListDialog.vue";
import dateUtility from "@/mixins/date";
import EngageEventFormDialog from "@/components/MainView/Engage/EventCalendar/components/EventFormDialog";
import { GET_EVENTS } from "@/store/modules/event";
import helperUtility from "@/mixins/helper";
import EngageEventInformationDialog from "@/components/MainView/Engage/EventCalendar/components/EventInformationDialog";
import moment from "moment";
export default {
    name: "engage-events",

    components: {
        EngageEventInformationDialog,
        EngageEventFormDialog,
        EventListDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    featuredEvents: 0,
                    sortBy: "desc",
                    viewBy: "table",
                    from: null,
                    to: null,
                },
                headers: [
                    { text: "Image", value: "image", sortable: false },
                    {
                        text: "Event Name",
                        value: "event_name",
                        sortable: false,
                    },
                    {
                        text: "Date and Time",
                        value: "event_date",
                        sortable: false,
                    },
                    { text: "Location", value: "location", sortable: false },
                    {
                        text: "Event Type",
                        value: "event_type",
                        sortable: false,
                    },
                    // {
                    //     text: "Invite Link",
                    //     value: "inviteLink",
                    //     sortable: false,
                    // },
                    {
                        text: "",
                        value: "action",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            calendar: {
                items: [],
                options: {
                    from: null,
                    to: null,
                },
                pagination: {
                    page: 1,
                },
                loading: false,
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            eventForm: { show: false, action: "", data: null },
            eventList: { show: false, action: "", data: null },
            eventInformationDialog: { show: false, data: null },
            selectedEngagePage: "/engage/events-calendar",
            engageItems: [
                {
                    text: "Sessions",
                    value: "/engage/sessions",
                },
                {
                    text: "Prayer Requests",
                    value: "/engage/prayer-requests",
                },
                {
                    text: "#TakeAMomentToPray",
                    value: "/engage/daily-devotions",
                },
                {
                    text: "Events",
                    value: "/engage/events-calendar",
                },
            ],
            show: false,
            focus: "",
            mode: "table",
        };
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getEventsTable();
            },
            deep: true,
        },

        ["table.pagination.perPage"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getEventsTable();
            },
        },

        async mode(value) {
            if (value === "calendar") {
                this.calendar.options = Object.assign(
                    {},
                    {
                        from: this.$refs.calendar.lastStart.date,
                        to: this.$refs.calendar.lastEnd.date,
                    }
                );
                await this.getEventsCalendar();
            } else {
                await this.getEventsTable();
            }
        },
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        dataContainers() {
            return [
                {
                    text: "Table",
                    value: "table",
                },

                {
                    text: "Calendar",
                    value: "calendar",
                },
            ];
        },

        getMonth() {
            return this.$refs.calendar.title.slice(0, -4);
        },

        getYear() {
            return this.$refs.calendar.title.split(" ").pop();
        },

        disabledFilter() {
            return this.mode === "calendar";
        },
    },

    methods: {
        onClickCalendarMore(e) {
            if (!this.eventInformationDialog.show) {
                this.eventList.show = true;
                this.eventList.data = {
                    events: this.calendar.items.filter(
                        (event) =>
                            moment(event.start).format("YYYY-MM-DD") == e.date
                    ),
                    date: e.date,
                };
            }
        },
        async getEventsTable() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_EVENTS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async getEventsCalendar() {
            const payload = {
                ...this.calendar.pagination,
                ...this.calendar.options,
                from: moment(this.calendar.options.from).format("YYYY-MM-DD"),
                to: moment(this.calendar.options.to).format("YYYY-MM-DD"),
            };

            this.calendar.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_EVENTS,
                payload
            );

            if (success) {
                const eventsAPI = data.data;
                this.calendar.items = eventsAPI.map((event) => ({
                    name: event.event_name,
                    start: dateUtility.toLocalDate(event.event_start),
                    end: !event.is_allday
                        ? dateUtility.toLocalDate(event.event_end)
                        : null,
                    data: Object.assign({}, event),
                }));
                this.calendar.loading = false;
                return;
            }
            this.calendar.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getEventsTable();
        },

        openEventFormDialog(action) {
            switch (action) {
                case "add":
                    this.eventForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.eventForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.eventInformationDialog.data
                        ),
                    };
                    this.eventInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.eventForm = { show: true, action, data: null };
                    break;
            }
        },

        clearDateRanges() {
            this.table.options.dateFrom = null;
            this.table.options.dateTo = null;
        },

        onOpenUpdateUserForm({ data }) {
            this.eventInformationDialog = Object.assign(
                {},
                {
                    show: false,
                    data: Object.assign({}, data),
                }
            );
            this.openEventFormDialog("edit");
        },

        formatDate({ is_allday, event_start, event_end }) {
            let start = dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(event_start)
            );
            if (!is_allday) {
                start = dateUtility.formatTimestampToSchedule(
                    dateUtility.toLocalDate(event_start)
                );
                const end = dateUtility.formatTimestampToSchedule(
                    dateUtility.toLocalDate(event_end)
                );

                return `${start} to ${end}`;
            }

            return `${start} (All Day)`;
        },

        openEventInformationDialog(data) {
            this.eventInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        changeView(to) {
            this.$router.push(to);
        },

        copyToClipboard(text) {
            helperUtility.copyText(text);
            this.$notify({
                group: "main-notifications",
                type: "success",
                title: "Copied to Clipboard",
                text: "Invite Link was copied to clipboard.",
            });
        },

        setToday() {
            this.focus = "";
        },

        async prev() {
            this.$refs.calendar.prev();
            this.$nextTick(async () => {
                this.calendar.options = Object.assign(
                    {},
                    {
                        from: this.$refs.calendar.lastStart.date,
                        to: this.$refs.calendar.lastEnd.date,
                    }
                );
                await this.getEventsCalendar();
            });
        },

        next() {
            this.$refs.calendar.next();
            this.$nextTick(async () => {
                this.calendar.options = Object.assign(
                    {},
                    {
                        from: this.$refs.calendar.lastStart.date,
                        to: this.$refs.calendar.lastEnd.date,
                    }
                );
                await this.getEventsCalendar();
            });
        },

        onClickCalendarEvent({ event }) {
            const { data } = event;
            this.openEventInformationDialog(data);
        },

        async onFinishUpdateDeleteEvent() {
            await this.getEventsTable();
            await this.getEventsCalendar();
        },

        async initiate() {
            this.selectedEngagePage = this.$route.path;
            await this.getEventsTable();
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}

.v-sheet {
    height: 50vh;
}
</style>
