<template>
    <base-dialog width="700" :is-open.sync="isOpenLocal">
        <div class="d-flex mt-8 ml-6">
            <p class="ml-2 font-size-26 font-weight-bold">
                {{ currentDate.slice(0, -4) }}
            </p>
            <p class="ml-2 font-size-26 success--text font-weight-bold">
                {{ currentDate.split(",")[1] }}
            </p>
        </div>

        <v-timeline dense align-top>
            <v-virtual-scroll
                :items="events"
                :item-height="200"
                :height="events?.length > 1 ? 500 : 200"
                bench="1"
            >
                <template v-slot:default="{ item }">
                    <v-timeline-item
                        :color="item.color"
                        fill-dot
                        icon="mdi-calendar"
                    >
                        <v-hover>
                            <template v-slot:default="{ isHovering }">
                                <v-card
                                    :color="item.color"
                                    width="550"
                                    id="event-card"
                                    ripple
                                    :class="isHovering ? 'pointer' : ''"
                                    class="card-container"
                                    height="170"
                                    @click="onClickCard(item)"
                                >
                                    <div class="text-container">
                                        <div class="header">
                                            {{ item.name }}
                                        </div>
                                        <span>{{ getTime(item) }}</span>
                                        <div class="text-desc">
                                            {{ item.data.description }}
                                        </div>
                                    </div>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-timeline-item>
                </template>
            </v-virtual-scroll>
        </v-timeline>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import moment from "moment";
import dateUtility from "@/mixins/date";
export default {
    components: {
        BaseDialog,
    },
    props: {
        isOpen: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: Object, required: false },
    },
    data() {
        return {
            isOpenLocal: this.isOpen,
            colors: [
                "blue",
                "indigo",
                "deep-purple",
                "cyan",
                "green",
                "yellow",
                "orange",
                "grey darken-1",
            ],
        };
    },
    computed: {
        focus: {
            get() {
                return this.data?.date;
            },
            set(newDate) {
                return newDate;
            },
        },
        currentDate() {
            return moment(this.data?.date).format("MMMM DD, YYYY");
        },
        events() {
            return this.data?.events.map((event) => {
                return {
                    ...event,
                    color: this.colors[this.random(0, this.colors.length - 1)],
                };
            });
        },
    },
    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },
        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
    },
    methods: {
        getTime(item) {
            // return `${moment(item.start).format("LT")} to ${moment(
            //     item.end
            // ).format("LT")}`;
            let startOut = moment(item.start).format("LT");
            let endOut = item.end
                ? ` to ${moment(item.end).format("LT")}`
                : " (All Day)";
            return startOut + endOut;
        },
        formatDate(timestamp) {
            return dateUtility.toSimpleDate(timestamp);
        },
        random(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },
        onClickCard(event) {
            this.$emit("onClickCalendarEvent", { event: event });
        },
    },
};
</script>

<style scoped lang="scss">
.custom-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 10px;
}

.pointer {
    cursor: pointer;
}

.card-container {
    width: 550px;
    height: 150px;
    background-color: red;

    &:hover {
        cursor: pointer;
    }
}

.main-desc {
    padding: 0 20px 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    height: 70%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
}

.header {
    font-size: 22px;
    font-weight: 500;
}

.text-desc {
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>
